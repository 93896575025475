import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import { useHistory } from 'react-router-dom';
import { FaFilePdf, FaFileWord, FaFileExcel } from 'react-icons/fa';
import { IoDocumentText } from 'react-icons/io5';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { MdPersonAddAlt } from 'react-icons/md';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Chip,
  Container,
  CardActions,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert';
import axios from 'axios';
import { Padding } from '@mui/icons-material';

const ShowDocuments = ({ documents, row, setDocuments, Alldataavailable }) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [filePreviews, setFilePreviews] = useState({});
  const [loadingState, setLoadingState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useContext(ThemeContext);
  const [allDocument, setAllDocument] = useState(true);
  const history = useHistory();
  const Company_CDD = documents?.documents?.filter((doc) =>
    [
      'businessRegistration',
      'ownershipStructure',
      'financialDocuments',
      'companyUtilityBill',
      'tax_declaration',
      'complianceDocumentation',
    ].some((substring) => doc.documentType.includes(substring))
  );

  const Onboarding_pack = documents?.documents?.filter((doc) =>
    ['ClientAgreement', 'CRA_sheet', 'purposeOfAccount'].some((substring) =>
      doc.documentType.includes(substring)
    )
  );

  const UBO_CDD = documents?.documents?.filter((doc) =>
    [
      'identificationDocumentfront',
      'identificationDocumentback',
      'utilityBill',
      'sourceoffund',
    ].some((substring) => doc.documentType.includes(substring))
  );

  const updateDocumentStatus = (documentPath, newStatus) => {
    setDocuments((prevDocuments) => ({
      ...prevDocuments,
      documents: prevDocuments.documents.map((doc) =>
        doc.documentPath === documentPath
          ? { ...doc, documentStatus: newStatus }
          : doc
      ),
    }));
  };

  const areAllDocumentsApproved = () => {
    if (documents && documents?.documents) {
      return documents.documents.every(
        (doc) => doc.documentStatus === 'approved'
      );
    }
    return false;
  };

  const handleButtonClick = async (action, path) => {
    const data = {
      userId: row._id,
      documentId: path,
      documentStatus: action,
    };
    setLoadingState((prevState) => ({
      ...prevState,
      [path + action]: true,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/documents/status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
      updateDocumentStatus(path, action);
      setIsLoading(false);
    } catch (err) {
      swal({
        title: 'Document Status Approval Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setLoadingState((prevState) => ({
        ...prevState,
        [path + action]: false,
      }));
    }
  };

  const handleApproveUser = async (userId) => {
    setIsSubmitting(true);
    try {
      let data = {
        userId: userId,
        kycStatus: 'approved',
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/update/kyc-status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
      swal({
        title: 'User Approved Successfully',
        icon: 'success',
        text: `${res.data.message}`,
        button: true,
      });

      history.push('/users');
    } catch (err) {
      swal({
        title: 'User Approval Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setIsSubmitting(false);
    }
  };
  const isExcelFile = (file) => {
    const validExtensions = ['.xls', '.xlsx'];
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
    return validExtensions.includes(fileExtension);
  };
  const handleFileUpload = (file, docType) => {
    if (!file) return;
    if (docType === 'CRA_sheet') {
      if (!isExcelFile(file)) {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          CRA_sheet: 'Please upload a valid Excel file for CRA sheet.',
        }));
        return;
      } else {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          CRA_sheet: null,
        }));
      }
    }

    setUploadedFiles((prevFiles) => ({ ...prevFiles, [docType]: file }));
    const fileUrl = URL.createObjectURL(file);
    setFilePreviews((prevPreviews) => ({
      ...prevPreviews,
      [docType]: fileUrl,
    }));
  };

  const Upload = async () => {
    try {
      if (Object.keys(uploadedFiles).length === 0) {
        swal({
          title: 'No Files Selected',
          icon: 'warning',
          text: 'Please select files to upload.',
          button: true,
        });
        return;
      }
      const formData = new FormData();

      Object.entries(uploadedFiles).forEach(([docType, file]) => {
        formData.append(docType, file);
      });
      setIsLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/documents?id=${row?._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setIsLoading(false);
      setUploadedFiles({});
      setFilePreviews({});
      swal({
        title: 'Success',
        icon: 'success',
        text: 'Company Documents Uploaded Successfully',
        button: true,
      });
      history.push('/users');
    } catch (error) {
      setIsLoading(false);
      swal({
        title: 'Company documents upload failed',
        icon: 'error',
        text: error.response?.data?.message || 'An error occurred.',
        button: true,
      });
    }
  };

  const requiredDocuments = [
    'ClientAgreement',
    'businessRegistration',
    'complianceDocumentation',
    'tax_declaration',
    'CRA_sheet',
  ];

  const personalDocuments = ['CRA_sheet', 'ClientAgreement'];

  const Submit = async () => {
    try {
      if (Object.keys(uploadedFiles).length === 0) {
        swal({
          title: 'No Files Selected',
          icon: 'warning',
          text: 'Please select files to upload.',
          button: true,
        });
        return;
      }
      const formData = new FormData();
      Object.entries(uploadedFiles).forEach(([docType, file]) => {
        formData.append(docType, file);
      });
      setIsLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/personal/documents?id=${row?._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setIsLoading(false);
      setUploadedFiles({});
      setFilePreviews({});
      swal({
        title: 'Success',
        icon: 'success',
        text: 'Personal Documents Uploaded Successfully',
        button: true,
      });
      history.push('/users');
    } catch (error) {
      setIsLoading(false);
      swal({
        title: 'Personal documents upload failed',
        icon: 'error',
        text: error.response?.data?.message || 'An error occurred.',
        button: true,
      });
    }
  };

  return documents && documents?.documents.length > 0 ? (
    <>
      <Container maxWidth="lg" className="mt-0">
        {
          <CardActions
            sx={{
              maxWidth: '100%',
              pb: 2,
              display: 'flex',
              gap: 4,
            }}
          >
            <Button
              variant={allDocument ? 'contained' : ''}
              size="large"
              onClick={() => setAllDocument(true)}
              sx={{ maxWidth: 250, fontSize: '1rem' }}
            >
              All Documents
            </Button>

            <Button
              variant={!allDocument ? 'contained' : ''}
              size="large"
              onClick={() => setAllDocument(false)}
              sx={{ maxWidth: 250, fontSize: '1rem' }}
            >
              Upload documents
            </Button>
          </CardActions>
        }
        {allDocument && (
          <Box>
            <h3 variant="h4" gutterBottom style={{ marginBottom: '60px' }}>
              {documents?.number_of_directors
                ? `Directors: ${documents.number_of_directors}`
                : 'Documents'}
            </h3>
            <DocumentSection
              icon={<IoDocumentText size={35} style={{ color: '#009193' }} />}
              title="Onboarding Documents"
              documents={Onboarding_pack}
              handleButtonClick={handleButtonClick}
              loadingState={loadingState}
              token={state.auth.accessToken}
            />

            <DocumentSection
              icon={<MdPersonAddAlt size={35} style={{ color: '#009193' }} />}
              title="User Documents"
              documents={UBO_CDD}
              handleButtonClick={handleButtonClick}
              loadingState={loadingState}
              token={state.auth.accessToken}
            />
            {row?.accountType === 'business' && (
              <DocumentSection
                icon={
                  <BsFillBuildingsFill size={35} style={{ color: '#009193' }} />
                }
                title="Company Documents"
                documents={Company_CDD}
                handleButtonClick={handleButtonClick}
                loadingState={loadingState}
                token={state.auth.accessToken}
              />
            )}
          </Box>
        )}
        {row?.accountType === 'business' && !allDocument && (
          <Grid container spacing={3}>
            {requiredDocuments.every((docType) =>
              documents?.documents.some((doc) => doc.documentType === docType)
            ) ? (
              <div
                className="text-center d-flex justify-content-center mt-3 align-items-center"
                style={{
                  minHeight: '500px',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '20px',
                }}
              >
                <h1>No Documents To Upload</h1>
              </div>
            ) : (
              requiredDocuments.map((docType) => {
                const foundDocument = documents?.documents.find(
                  (doc) => doc.documentType === docType
                );

                if (!foundDocument) {
                  return (
                    <Grid item xs={12} sm={6} key={docType}>
                      <div
                        style={{
                          minHeight: '250px',
                          width: '100%',
                          height: '100%',
                          minWidth: '350px',
                          border: '1px solid #d0d4e2',
                          borderRadius: '10px',
                          marginBottom: '12px',
                          padding: '10px',
                        }}
                      >
                        <h4 className="text-center">{docType}</h4>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '10px',
                            height: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ maxWidth: 250, fontSize: '1rem' }}
                          >
                            Upload Document
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0], docType)
                              }
                            />
                          </Button>
                          {docType === 'CRA_sheet' && errorMessages[docType] && (
                            <Alert severity="error" sx={{ marginTop: 1 }}>
                              {errorMessages[docType]}
                            </Alert>
                          )}
                          {uploadedFiles[docType] &&
                          uploadedFiles[docType].type.startsWith('image/') ? (
                            <img
                              src={filePreviews[docType]}
                              alt={uploadedFiles[docType].name}
                              style={{
                                width: '100%',
                                maxWidth: '250px',
                                marginTop: '10px',
                              }}
                            />
                          ) : (
                            uploadedFiles[docType] &&
                            (uploadedFiles[docType].type ===
                            'application/pdf' ? (
                              <iframe
                                src={filePreviews[docType]}
                                title="PDF Preview"
                                style={{
                                  width: '100%',
                                  height: '250px',
                                  marginTop: '10px',
                                }}
                              ></iframe>
                            ) : (
                              <div>
                                <p>{uploadedFiles[docType].name}</p>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })
            )}
          </Grid>
        )}

        {row?.accountType === 'personal' && !allDocument && (
          <Grid container spacing={3}>
            {personalDocuments.every((docType) =>
              documents?.documents.some((doc) => doc.documentType === docType)
            ) ? (
              <div
                className="text-center d-flex justify-content-center mt-3 align-items-center"
                style={{
                  minHeight: '500px',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '20px',
                }}
              >
                <h1>No Documents To Upload</h1>
              </div>
            ) : (
              personalDocuments?.map((docType) => {
                const foundDocument = documents?.documents.find(
                  (doc) => doc.documentType === docType
                );

                if (!foundDocument) {
                  return (
                    <Grid item xs={12} sm={6} key={docType}>
                      <div
                        style={{
                          minHeight: '250px',
                          width: '100%',
                          height: '100%',
                          minWidth: '350px',
                          border: '1px solid #d0d4e2',
                          borderRadius: '10px',
                          marginBottom: '12px',
                          padding: '10px',
                        }}
                      >
                        <h4 className="text-center">{docType}</h4>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '10px',
                            height: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ maxWidth: 250, fontSize: '1rem' }}
                          >
                            Upload Document
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0], docType)
                              }
                            />
                          </Button>
                          {docType === 'CRA_sheet' && errorMessages[docType] && (
                            <Alert severity="error" sx={{ marginTop: 1 }}>
                              {errorMessages[docType]}
                            </Alert>
                          )}
                          {uploadedFiles[docType] &&
                          uploadedFiles[docType].type.startsWith('image/') ? (
                            <img
                              src={filePreviews[docType]}
                              alt={uploadedFiles[docType].name}
                              style={{
                                width: '100%',
                                maxWidth: '250px',
                                marginTop: '10px',
                              }}
                            />
                          ) : (
                            uploadedFiles[docType] &&
                            (uploadedFiles[docType].type ===
                            'application/pdf' ? (
                              <iframe
                                src={filePreviews[docType]}
                                title="PDF Preview"
                                style={{
                                  width: '100%',
                                  height: '250px',
                                  marginTop: '10px',
                                }}
                              ></iframe>
                            ) : (
                              <div>
                                <p>{uploadedFiles[docType].name}</p>
                                {docType === 'CRA_sheet' &&
                                  !isExcelFile(uploadedFiles[docType]) && (
                                    <Alert
                                      severity="error"
                                      sx={{ marginTop: 1 }}
                                    >
                                      Please upload a valid Excel file for CRA
                                      sheet.
                                    </Alert>
                                  )}
                                {errorMessages[docType] && (
                                  <Alert severity="error" sx={{ marginTop: 1 }}>
                                    {errorMessages[docType]}
                                  </Alert>
                                )}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })
            )}
          </Grid>
        )}
      </Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: '1rem',
          marginTop: '1rem',
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ maxWidth: 150 }}
          onClick={() => Alldataavailable('')}
        >
          Prev
        </Button>
        {!allDocument &&
          (!requiredDocuments.every((docType) =>
            documents?.documents.some((doc) => doc.documentType === docType)
          ) ||
          !personalDocuments.every((docType) =>
            documents?.documents.some((doc) => doc.documentType === docType)
          ) ? (
            <Button
              variant="contained"
              component={LoadingButton}
              loading={isLoading}
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => {
                if (row?.accountType === 'business') {
                  Upload();
                } else {
                  Submit();
                }
              }}
            >
              Submit
            </Button>
          ) : null)}

        {areAllDocumentsApproved() &&
          !isLoading &&
          row?.kyc?.status !== 'approved' && (
            <Button
              component={LoadingButton}
              loading={isSubmitting}
              variant="contained"
              onClick={() => handleApproveUser(row._id)}
              // type="submit"
              size="small"
              sx={{
                maxWidth: 150,
                marginLeft: 'auto',
                marginRight: 5,
                marginTop: 2,
              }}
            >
              Approve User
            </Button>
          )}
      </div>
    </>
  ) : (
    <>
      <div
        className="text-center d-flex justify-content-center align-items-center"
        style={{
          minHeight: '500px',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
      >
        <h1>No Documents</h1>
      </div>
      <Button
        variant="contained"
        size="small"
        className="mt-3"
        sx={{ maxWidth: 150 }}
        onClick={() => Alldataavailable('')}
      >
        Prev
      </Button>
    </>
  );
};

function DocumentList({ documents, handleButtonClick, loadingState, token }) {
  return (
    <Grid container spacing={3}>
      {documents?.map((doc, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <div
            className="w-100"
            style={{
              minHeight: '400px',
              minWidth: '349px',
              border: '1px solid #d0d4e2',
              borderRadius: '10px',
              overflow: 'hidden',
              marginTop: '24px',
              marginBottom: '12px',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <a
                href={`${process.env.REACT_APP_BACKEND_API}/documents/${doc.documentPath}/${token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {doc?.documentPath?.endsWith('.pdf') ? (
                  <FaFilePdf size={295} style={{ padding: '20px' }} />
                ) : doc?.documentPath?.endsWith('.doc') ||
                  doc?.documentPath?.endsWith('.docx') ? (
                  <FaFileWord size={295} style={{ padding: '20px' }} />
                ) : doc?.documentPath?.endsWith('.xls') ||
                  doc?.documentPath?.endsWith('.xlsx') ? (
                  <FaFileExcel size={295} style={{ padding: '20px' }} />
                ) : (
                  <CardMedia
                    component="img"
                    sx={{
                      height: 300,
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    image={`${process.env.REACT_APP_BACKEND_API}/documents/${doc.documentPath}/${token}`}
                    alt={doc?.documentType}
                  />
                )}
              </a>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {doc?.documentType}
                  </Typography>
                  <Chip
                    label={doc?.documentStatus}
                    color={
                      doc?.documentStatus === 'approved'
                        ? 'success'
                        : doc?.documentStatus === 'rejected'
                        ? 'error'
                        : 'default'
                    }
                    sx={{ mb: 1 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                    alignItems: { xs: 'stretch', sm: 'center' },
                  }}
                >
                  {doc?.documentStatus === 'pending' && (
                    <>
                      <Button
                        variant="contained"
                        loading={loadingState[doc?.documentPath + 'approved']}
                        component={LoadingButton}
                        color="success"
                        sx={{ width: '150px' }}
                        onClick={() =>
                          handleButtonClick('approved', doc.documentPath)
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        loading={loadingState[doc?.documentPath + 'rejected']}
                        component={LoadingButton}
                        style={{ backgroundColor: '#ee3232' }}
                        sx={{ width: '150px' }}
                        onClick={() =>
                          handleButtonClick('rejected', doc.documentPath)
                        }
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

function DocumentSection({
  icon,
  title,
  documents,
  handleButtonClick,
  loadingState,
  token,
}) {
  return (
    <Grid item xs={12} style={{ marginBottom: '40px' }}>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', alignItems: 'center', paddingLeft: '24px' }}
      >
        <div
          style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
        >
          {icon}
        </div>
        <h5
          style={{
            fontSize: '25px',
            margin: '0',
            color: '#5a5a60',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </h5>
      </Grid>
      <DocumentList
        documents={documents}
        handleButtonClick={handleButtonClick}
        loadingState={loadingState}
        token={token}
      />
    </Grid>
  );
}

export default ShowDocuments;

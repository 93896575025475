import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { TableRowInvestment } from './styles';
import { FaSort } from 'react-icons/fa';

const TableWrapperComponent = ({ headerCells, children, sortDataFunc }) => {
  const { palette } = useTheme();
  const [sliceData, setSliceData] = useState(10);

  return (
    <TableContainer
      component={Paper}
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '10px',
          boxShadow: 'none',
          color: 'whitesmoke',
        },
      }}
    >
      <Table>
        <TableHead
          sx={{
            background: '#33a6a9',
          }}
        >
          <TableRow>
            {headerCells.map((item, id) => {
              return (
                <TableCell
                  key={id}
                  sx={{
                    color: 'whitesmoke',
                    fontSize: '0.92rem',
                    lineHeight: '140%',
                    fontWeight: 600,
                    padding: '1.5em 0.88rem',
                    width: item.width ? '12.5rem' : 'auto',
                    textAlign: item.align ?? 'left',
                  }}
                >
                  {item.label}
                  {sortDataFunc && (
                    <Box
                      sx={{
                        display: 'inline-flex',
                        marginLeft: '0.5rem',
                        cursor: 'pointer',
                        color: 'whitesmoke',
                      }}
                    >
                      <FaSort
                        onClick={() => sortDataFunc(item.sortName)}
                        color="whitesmoke"
                      />
                      {/* <SortArrows   /> */}
                    </Box>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {children?.slice(0, sliceData)}
          {children?.length > 10 && sliceData !== Infinity && (
            <TableRowInvestment
              sx={{
                '&:hover': {
                  background: palette.white.main,
                },
              }}
            >
              {headerCells.slice(0, -1).map((cell, id) => (
                <TableCell key={id} />
              ))}
              <TableCell
                align="right"
                sx={{
                  paddingRight: '2.81rem',
                }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    color: palette.green500.main,
                    '&:hover': {
                      opacity: '0.7',
                    },
                  }}
                  onClick={() => setSliceData(Infinity)}
                >
                  View All
                </Box>
              </TableCell>
            </TableRowInvestment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableWrapperComponent;

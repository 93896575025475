import axios from 'axios';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import swal from 'sweetalert';
import { useLocation, useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import { logout } from '../../layouts/nav/Icons.js';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, Form } from 'formik';
import * as yup from 'yup';
import BusinessUpdateForm from './BusinessUpdate';
import { ThemeContext } from '../../../context/ThemeContext';
import {
  useFetchKYCStatus,
  useInitiateKYC,
  useSetKYCVerified,
} from 'services/User';
import Nav from '../../layouts/nav';
import ScrollToTop from '../../layouts/ScrollToTop';
import ProfileUpdateForm from './ProfileUpdateForm';
import StatusModal from './StatusModal';
import '../../step.css';
import { grey } from '@mui/material/colors';
import { IconWrapper } from './Icon/IconWrapper';
import { VerifyIcon } from './Icon/Verify';

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(40, 'First name should be less than 40 characters')
    .required('First Name is required'),
  last_name: yup
    .string()
    .max(40, 'Last name should be less than 40 characters')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone_number: yup.string().required('Phone Number is required'),
  phone_number_code: yup.string().required('Phone Number Code is required'),
  place_of_birth: yup.string().required('Birth place is required'),
  national_id: yup
    .number()
    .required('National ID  is required')
    .positive('National ID  must be a positive number'),
  gender: yup
    .string()
    .oneOf(['male', 'female'], 'Gender must be either male or female')
    .required('Gender is required'),
  birthday: yup
    .string()
    .required('Birthday is required')
    .test({
      name: 'noFutureDate',
      test: (value) => new Date().getTime() > new Date(value).getTime(),
      message: 'Please select a past date',
    })
    .test({
      name: 'validAge',
      test: (value) => getAge(value) > 18,
      message: 'Users below 18 years old cannot open a bank account',
    }),
  address_street: yup
    .string()
    .required('Street is required')
    .min(3, 'Please enter a street name with more than 3 characters'),
  address_city: yup
    .string()
    .required('City is required')
    .min(3, 'Please enter city name with more than 3 characters'),
  address_postal_code: yup
    .string()
    .required('Postal/zip code is required')
    .matches(/^[0-9]+$/, 'Postal/Zip code should be only numbers'),
  address_country: yup.string().required('Country is required'),
  nationality: yup.string().required('Nationality is required'),
  accept_terms_condition: yup
    .bool()
    .oneOf([true], 'Please accept the terms and conditions.'),

  isBusiness: yup.boolean(),
  company_name: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Company name is required')
      .min(3, 'Please enter company name with more than 3 characters'),
    otherwise: yup.string().notRequired(),
  }),
  company_Email: yup.string().when('isBusiness', {
    is: true,
    then: yup.string().required('Email is required').email('Invalid email'),
    otherwise: yup.string().notRequired(),
  }),
  country_id: yup.string().when('isBusiness', {
    is: true,
    then: yup.string().required('Country is required'),
    otherwise: yup.string().notRequired(),
  }),
  legal_form_id: yup.string().when('isBusiness', {
    is: true,
    then: yup.string().required('Legal form is required'),
    otherwise: yup.string().notRequired(),
  }),
  company_size_id: yup.string().when('isBusiness', {
    is: true,
    then: yup.string().required('Company size is required'),
    otherwise: yup.string().notRequired(),
  }),
  taxId: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Tax Id is required')
      .matches(/^[0-9]+$/, 'Tax Id should be only numbers'),
    otherwise: yup.string().notRequired(),
  }),
  company_state: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('company state is required')
      .min(3, 'Please enter city name with more than 3 characters'),
    otherwise: yup.string().notRequired(),
  }),
  company_street: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Company Street is required')
      .min(3, 'Please enter street name with more than 3 characters'),
    otherwise: yup.string().notRequired(),
  }),
  registration_date: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Registration Date is required')
      .test({
        name: 'noFutureDate',
        test: (value) => new Date().getTime() > new Date(value).getTime(),
        message: 'Please select a past date',
      }),
    otherwise: yup.string().notRequired(),
  }),
  company_city: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Company_city is required')
      .min(3, 'Please enter city name with more than 3 characters'),
    otherwise: yup.string().notRequired(),
  }),
  company_phone_number: yup.string().when('isBusiness', {
    is: true,
    then: yup.string().required('Phone number is required'),
    otherwise: yup.string().notRequired(),
  }),
  company_postal_code: yup.string().when('isBusiness', {
    is: true,
    then: yup
      .string()
      .required('Postal/zip code is required')
      .matches(/^[0-9]+$/, 'Postal/Zip code should be only numbers'),
    otherwise: yup.string().notRequired(),
  }),
});

const ProfileUpdate = () => {
  const { path, activeEvent, setActiveEvent, state, user, initUserDetails } =
    useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  // const socket = useRef();

  // const { mutateAsync: setKYCVerified, isLoading } = useSetKYCVerified();
  // const { mutateAsync: initiateKYC, data: kycInfo } = useInitiateKYC();
  const {
    data: kycStatus,
    refetch: fetchKYCStatus,
    isSuccess: isKycStatusFetched,
  } = useFetchKYCStatus();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tabletS'));

  const [verify, setVerify] = useState(true);
  const [loading, setLoading] = useState(false);
  const [personal, setPersonal] = useState(true);

  // const [initKYC, setInitKYC] = useState(false);
  // const [initKYCVerifyModal, setInitKYCVerifyModal] = useState(false);
  // const [initKYCReviewModal, setInitKYCReviewModal] = useState(false);
  // const [initKYCVerifiedModal, setInitKYCVerifiedModal] = useState(false);

  let initialProfilePage = location.pathname.includes('/profile-update');
  const isBusinessProfile = user?.accountType === 'business';

  const KYCStatus = useMemo(() => {
    let status = user?.kyc?.status || 'pending';
    switch (kycStatus) {
      /** User details not filled */
      case 'pending': {
        status = 'pending';
        break;
      }
      /** User details filled and able to initiate KYC */
      case 'initiable': {
        status = 'initiable';
        break;
      }
      /** KYC is on hold */
      case 'review': {
        status = 'review';
        break;
      }
      /** KYC is on hold */
      case 'hold': {
        status = 'hold';
        break;
      }
      /** KYC completed */
      case 'approved': {
        status = 'approved';
        break;
      }
      /** KYC rejected */
      case 'rejected': {
        status = 'rejected';
        break;
      }
    }

    return status;
  }, [kycStatus]);

  useEffect(() => {
    if (
      user?.accountType === 'business' &&
      user?.company_details &&
      initialProfilePage
    ) {
      history.push('/company-structure');
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      const results = await Promise.allSettled([
        submitPersonalInfo({
          first_name: data.first_name,
          last_name: data.last_name,
          birthday: data.birthday,
          address_street: data.address_street,
          address_city: data.address_city,
          address_postal_code: data.address_postal_code,
          address_country: data.address_country,
          nationality: data.nationality,
          national_id: data.national_id,
          gender: data.gender,
          place_of_birth: data.place_of_birth,
          accept_terms_condition: data.accept_terms_condition,
        }),
        isBusinessProfile &&
          SubmitBusinessInfo({
            company_name: data.company_name,
            taxNumber: data.taxId,
            registration_date: data.registration_date,
            company_size_id: data.company_size_id,
            address: data.company_street,
            city: data.company_city,
            postcode: data.company_postal_code,
            state: data.company_state,
            country_id: data.country_id,
            country_tax_residence_id: data?.country_id,
            email: data.company_Email,
            legal_form_id: data.legal_form_id,
            lei_code: data.lei_code,
            website: data.website,
            risk_level: data.risk_level,
            taxpayer_identification_number: data.vat_number,
            mobile_phone_number: data.company_phone_number,
          }),
      ]);

      const allFulfilled = results.every(
        (result) => result.status === 'fulfilled'
      );

      if (allFulfilled) {
        swal({
          title: 'Profile Save Success',
          icon: 'success',
          text: 'Your profile has been saved successfully',
          button: true,
        });
        if (user?.accountType === 'personal') {
          history.push('/profile-verification');
        } else {
          history.push('/company-structure', { key: true });
        }
      } else {
        const errors = results
          .filter((result) => result.status === 'rejected')
          .map((result) => result.reason.message);
        swal({
          title: 'Profile Save Failed',
          icon: 'error',
          text: `${errors.join('& ')}`,
          button: true,
        });
      }
    } catch (error) {
      swal({
        title: 'Unexpected Error',
        icon: 'error',
        text: 'An unexpected error occurred. Please try again.',
        button: true,
      });
    }
  };

  const fromikBag = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit,
  });

  const handleChange = async (newPersonal) => {
    if (!newPersonal) {
      const errors = await fromikBag.validateForm();
      fromikBag.setErrors(errors);
      fromikBag.setTouched(
        Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      );
      if (Object.keys(errors).length === 0) {
        setPersonal(false);
      }
    } else {
      setPersonal(true);
    }
  };

  useEffect(() => {
    if (user) {
      fromikBag.resetForm({
        values: {
          first_name: user.full_name?.first_name || '',
          last_name: user.full_name?.last_name || '',
          email: user.email,
          gender: user.gender || '',
          national_id: user.national_id || '',
          place_of_birth: user.place_of_birth || '',
          phone_number:
            user.phone_number_code.replace('+', '') + user.phone_number,
          phone_number_code: user.phone_number_code,
          birthday: user.date_of_birth,
          address_street: user.address?.address_street || '',
          address_city: user.address?.address_city || '',
          address_postal_code: user.address?.address_postal_code || '',
          address_country: user.address?.address_iso_country || '',
          nationality: user.nationality || '',
          accept_terms_condition: true,
          isBusiness: user.accountType === 'business' && !personal,
          company_name: user.company_details?.name || '',
          company_phone_number: user.company_details?.mobile_phone_number || '',
          company_Email: user.company_details?.email || '',
          registration_date: user.company_details?.registration_date
            ? new Date(user.company_details.registration_date)
                .toISOString()
                .split('T')[0]
            : '',
          company_street: user.company_details?.address || '',
          company_city: user.company_details?.city || '',
          company_postal_code: user.company_details?.postcode || '',
          company_state: user.company_details?.state || '',
          risk_level: user.company_details?.risk_level || '',
          country_id: user.company_details?.country_id || '',
          taxId: user.company_details?.code || '',
          vat_number:
            user.company_details?.taxpayer_identification_number || '',
          lei_code: user.company_details?.lei_code || '',
          company_size_id: user.company_details?.company_size_id || '',
          legal_form_id: user.company_details?.legal_form_id || '',
          planned_income: user.company_details?.planned_income || '',
          planned_expenditure: user.company_details?.planned_expenditure || '',
          website: user.company_details?.website || '',
        },
      });
    }
  }, [user]);

  useEffect(() => {
    fromikBag.setFieldValue(
      'isBusiness',
      user.accountType === 'business' && !personal
    );
  }, [personal]);

  const submitPersonalInfo = async (data) => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/self`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
      await fetchKYCStatus();
      initUserDetails();
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Profile Update Failed');
    } finally {
      setLoading(false);
    }
  };

  const SubmitBusinessInfo = async (data) => {
    if (!data) return;

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
    } catch (error) {
      throw new Error(
        error.response?.data?.message || 'Business Update Failed'
      );
    }
  };

  return (
    <div>
      <div
        id={`${initialProfilePage ? 'main-wrapper' : ''}`}
        className={`${initialProfilePage ? 'show' : 'mh100vh'}`}
      >
        {initialProfilePage && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(!activeEvent)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
            initialProfilePage ? 'content-body' : ''
          }`}
        >
          <div
            className={initialProfilePage ? 'container-fluid' : ''}
            style={{ minHeight: window.screen.height - 160 }}
          >
            {isBusinessProfile && (
              <CardActions
                sx={{
                  maxWidth: '100%',
                  margin: 'auto',
                  pl: 5,
                  pb: 2,
                  display: 'flex',
                  gap: 4,
                }}
              >
                <Button
                  variant={personal ? 'contained' : ''}
                  size="large"
                  onClick={() => handleChange(true)}
                  sx={{ maxWidth: 250, fontSize: '1rem' }}
                >
                  Personal Information
                </Button>

                <Button
                  variant={personal ? '' : 'contained'}
                  size="large"
                  onClick={() => handleChange(false)}
                  sx={{ maxWidth: 250, fontSize: '1rem' }}
                >
                  Business Information
                </Button>
              </CardActions>
            )}

            <Card
              sx={{
                mx: 4,
                py: 5,
                boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
                borderRadius: 4,
              }}
            >
              <FormikProvider value={fromikBag}>
                <Form onSubmit={fromikBag.handleSubmit}>
                  <CardContent
                    sx={
                      isBusinessProfile
                        ? {
                            maxWidth: '100%',
                            margin: 'auto',
                          }
                        : {
                            maxWidth: '80%',
                            margin: 'auto',
                          }
                    }
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      align="center"
                      fontWeight="medium"
                      mb={6}
                    >
                      Your {personal ? 'Personal' : 'Business'} Information
                    </Typography>

                    {initialProfilePage &&
                      ['pending', 'initiable'].includes(KYCStatus) && (
                        <Typography
                          variant="h5"
                          gutterBottom
                          align="center"
                          fontWeight="medium"
                          mx="auto"
                          mb={6}
                          color="var(--primary)"
                          sx={{ maxWidth: '60%' }}
                        >
                          To verify your {personal ? 'personal' : 'Business'}{' '}
                          identity please fill out the information below
                        </Typography>
                      )}

                    <Box
                      sx={{
                        maxWidth: isMobile ? '80%' : '60%',
                        margin: 'auto',
                      }}
                    >
                      {personal ? (
                        <ProfileUpdateForm
                          isBusinessProfile={isBusinessProfile}
                          isSavedOnce={
                            KYCStatus === 'review' ||
                            KYCStatus === 'hold' ||
                            KYCStatus === 'initiable'
                          }
                          isApproved={KYCStatus === 'approved'}
                          user={user}
                        />
                      ) : (
                        isBusinessProfile && (
                          <BusinessUpdateForm
                            isBusinessProfile={isBusinessProfile}
                            isSavedOnce={
                              KYCStatus === 'review' ||
                              KYCStatus === 'hold' ||
                              KYCStatus === 'initiable'
                            }
                            isApproved={KYCStatus === 'approved'}
                          />
                        )
                      )}
                    </Box>
                  </CardContent>
                  {isBusinessProfile && personal ? (
                    <CardActions
                      sx={{
                        maxWidth: '60%',
                        margin: 'auto',
                        pr: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleChange(false)}
                        size="small"
                        sx={{ maxWidth: 150 }}
                      >
                        Next
                      </Button>
                    </CardActions>
                  ) : (
                    <CardActions
                      sx={{
                        maxWidth: '60%',
                        margin: 'auto',
                        pr: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        component={LoadingButton}
                        loading={loading}
                        variant="contained"
                        type="submit"
                        size="small"
                        sx={{ maxWidth: 150 }}
                      >
                        {!loading && 'save'}
                      </Button>
                    </CardActions>
                  )}
                </Form>
              </FormikProvider>
            </Card>

            <StatusModal
              open={verify && initialProfilePage}
              illustration={
                <Stack alignItems="center">
                  <IconWrapper>
                    <VerifyIcon />
                  </IconWrapper>
                </Stack>
              }
              title="Verify your identity"
              children={
                <Stack alignItems="center" gap={3}>
                  <Typography color={grey[700]} textAlign="center">
                    We need to collect some personal information to verify your
                    identity before we can open your account
                  </Typography>

                  <Button
                    variant="contained"
                    onClick={() =>
                      // window.open(kycInfo?.data?.redirect_url, "_self")
                      setVerify(false)
                    }
                    sx={{ maxWidth: 250, textTransform: 'none' }}
                  >
                    Start Identity Verification
                  </Button>
                </Stack>
              }
            />
          </div>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
};

export default ProfileUpdate;

import { queryClient } from 'index';
import { accountCreationParams } from 'jsx/pages/Accounts/AccountCreation';
import AccountListSidebar from 'jsx/pages/Accounts/AccountList/AccountListSidebar';
import MetisMenu from 'metismenujs';
import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, withRouter } from 'react-router-dom';
import { useCheckIsEmployeeQuery } from 'services/Employee';

import { AccountType } from '../..';
import { ThemeContext } from '../../../context/ThemeContext';
import {
  accounts,
  cards,
  dashboard,
  integration,
  investment,
  logout,
  supports,
  transactions,
  transfers,
  EmployeeMgmt,
  users,
} from './Icons';
import axios from 'axios';
import { API } from '../../../services/Api';
import swal from 'sweetalert';
import { store } from '../../../store/store';

export async function logoutUser() {
  await axios
    .post(
      process.env.REACT_APP_BACKEND_API + API.v1.user.logout,
      {},
      {
        headers: {
          Authorization: `Bearer ${store.getState().auth.auth.accessToken}`,
          'x-refresh': `${store.getState().auth.auth.refreshToken}`,
        },
      }
    )
    .then((response) => {
      window.location.reload();
      queryClient.clear();
      localStorage.removeItem('userDetails');
    })
    .catch((error) => {
      console.log(error);
      // swal({
      //   title: 'Error',
      //   icon: 'error',
      //   text: 'Error during logout',
      //   button: 'Try Again',
      // });
    });
}

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      showAccounts: false,
      loveEmoji: false,
    };
    this.handleShowAccount = this.handleShowAccount.bind(this);
  }

  handleShowAccount = () => {
    this.setState({ showAccounts: !this.state.showAccounts });
  };

  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector('.nav-control');
    var hamburgerIcon = document.querySelector('.nav-control .hamburger');
    var aaa = document.querySelector('#main-wrapper');

    function toggleFunc() {
      if (hamburgerIcon.classList.contains('is-active')) {
        hamburgerIcon.classList.remove('is-active');
      } else {
        hamburgerIcon.classList.add('is-active');
      }
      return aaa.classList.toggle('menu-toggle');
    }

    function hideSidebarFunc() {
      hamburgerIcon.classList.remove('is-active');
      aaa.classList.remove('menu-toggle');
    }

    btn.addEventListener('click', toggleFunc);
    this.closeOnOutsideClick(hamburgerIcon, hideSidebarFunc);
  }

  closeOnOutsideClick = (element, handler) => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!element || element.contains(event.target)) {
        return;
      }
      handler(event);
    };

    if (window.innerWidth < 769) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  };

  LogoutHandler = () => {
    logoutUser();
  };

  openAccCreationModal = () => {
    const { history, location } = this.props;
    history.push({
      pathname: location.pathname,
      search: accountCreationParams,
    });
  };

  render() {
    const { enableReimbursement } = this.props;
    const { user, features, isFeatureEnabled } = this.context;

    /// Path
    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];

    return (
      <>
        {user?.status ? ( // return user?.status === 'approved' when the api works correctly
          <div className="deznav bg-white">
            {/* <PerfectScrollbar className="deznav-scroll"> */}
            <MM className="metismenu" id="menu">
              <li
                className={`${
                  path === 'dashboard' || path === '' ? 'mm-active' : ''
                }`}
                onClick={() => this.props.onClick()}
              >
                <Link className="has-arrow ai-icon" to="/dashboard">
                  {dashboard}
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
              {/*{![AccountType.AG, AccountType.A].includes(user?.accountType) && (*/}
              {/*  <li*/}
              {/*    className={`mb-1 ${path === 'accounts' ? 'mm-active' : ''}`}*/}
              {/*  >*/}
              {/*    <Link*/}
              {/*      className="has-arrow ai-icon"*/}
              {/*      to="/accounts"*/}
              {/*      onClick={() => this.props.onClick()}*/}
              {/*    >*/}
              {/*      {accounts}*/}
              {/*      <span className="nav-text">Accounts</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}

              {user.accountType !== AccountType.S && (
                <li
                  className={`mb-1 ${path === 'accounts' ? 'mm-active' : ''}`}
                >
                  <Link
                    className="has-arrow ai-icon"
                    to="/accounts"
                    onClick={() => this.props.onClick()}
                  >
                    {accounts}
                    <span className="nav-text">Accounts</span>
                  </Link>
                </li>
              )}

              {user.accountType !== AccountType.S && (
                <AccountListSidebar
                  onAccountClick={() => this.props.onClick()}
                />
              )}

              {user?.ledgers?.length < user?.limit?.account &&
                ![AccountType.AG, AccountType.A].includes(
                  user?.accountType
                ) && (
                  <li>
                    <a
                      onClick={
                        user?.kyc?.status === 'approved'
                          ? this.openAccCreationModal
                          : undefined
                      }
                      className="cursor-pointer ai-icon"
                    >
                      + <span className="nav-text">Open an account</span>
                    </a>
                  </li>
                )}
              {/*{(user?.accountType === AccountType.B ||*/}
              {/*  user?.accountType === AccountType.P) && (*/}
              {/*  <li className={`${path === 'investment' ? 'mm-active' : ''}`}>*/}
              {/*    <Link*/}
              {/*      className="has-arrow ai-icon"*/}
              {/*      to="/investment"*/}
              {/*      onClick={() => this.props.onClick()}*/}
              {/*    >*/}
              {/*      {investment}*/}
              {/*      <span className="nav-text">Investment</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              <li
                className={`${
                  path === 'admin-transactions' ? 'mm-active' : ''
                }`}
                onClick={() => this.props.onClick()}
              >
                {user.accountType === AccountType.S && (
                  <Link
                    className="has-arrow ai-icon"
                    to={
                      user.accountType === AccountType.S
                        ? '/admin-transactions'
                        : '/transactions'
                    }
                  >
                    {transactions}
                    <span className="nav-text">Transactions</span>
                  </Link>
                )}
              </li>
              <li
                className={`${path === 'transactions' ? 'mm-active' : ''}`}
                onClick={() => this.props.onClick()}
              >
                {user.accountType !== AccountType.S && (
                  <Link className="has-arrow ai-icon" to={'/transactions'}>
                    {transactions}
                    <span className="nav-text">Transactions</span>
                  </Link>
                )}
              </li>
              {/*{user?.accountType === AccountType.S && (*/}
              {/*  <li*/}
              {/*    className={`${*/}
              {/*      path === 'remittance-transactions' ? 'mm-active' : ''*/}
              {/*    }`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link*/}
              {/*      className="has-arrow ai-icon"*/}
              {/*      to="/remittance-transactions"*/}
              {/*    >*/}
              {/*      {transfers}*/}
              {/*      <span className="nav-text">Remittance Transactions</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/*{user?.accountType === AccountType.S && (*/}
              {/*  <li*/}
              {/*    className={`${path === 'crypto-balance' ? 'mm-active' : ''}`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link className="has-arrow ai-icon" to="/crypto-balance">*/}
              {/*      {transfers}*/}
              {/*      <span className="nav-text">Cyrto Balance</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/*{![AccountType.AG, AccountType.A].includes(user?.accountType) && (*/}
              {/*  <li*/}
              {/*    className={`${path === 'send-money' ? 'mm-active' : ''}`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link*/}
              {/*      className="has-arrow ai-icon"*/}
              {/*      to={*/}
              {/*        user.accountType === AccountType.S*/}
              {/*          ? '/admin-send-money'*/}
              {/*          : '/send-money'*/}
              {/*      }*/}
              {/*    >*/}
              {/*      {transfers}*/}
              {/*      <span className="nav-text">Send money</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {user.accountType !== AccountType.S && (
                <li
                  className={`${path === 'send-money' ? 'mm-active' : ''}`}
                  onClick={() => this.props.onClick()}
                >
                  <Link className="has-arrow ai-icon" to={'/send-money'}>
                    {transfers}
                    <span className="nav-text">Send money</span>
                  </Link>
                </li>
              )}
              {/*{user?.accountType === AccountType.S && (*/}
              {/*  <li*/}
              {/*    className={`${path === 'system-users' ? 'mm-active' : ''}`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link className="has-arrow ai-icon" to="/fees-setup">*/}
              {/*      {transfers}*/}
              {/*      <span className="nav-text">Fees Setup</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/*{user?.accountType === AccountType.S && (*/}
              {/*  <li*/}
              {/*    className={`${path === 'system-users' ? 'mm-active' : ''}`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link className="has-arrow ai-icon" to="/exchange-rate">*/}
              {/*      {transfers}*/}
              {/*      <span className="nav-text">Exchange Rates</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {user?.accountType === AccountType.S && (
                <li
                  className={`${path === 'users' ? 'mm-active' : ''}`}
                  onClick={() => this.props.onClick()}
                >
                  <Link className="has-arrow ai-icon" to="/users">
                    {users}
                    <span className="nav-text">Users</span>
                  </Link>
                </li>
              )}
              {/*{isFeatureEnabled(features.cards) &&*/}
              {/*  (user?.accountType === AccountType.B ||*/}
              {/*    user?.accountType === AccountType.P) && (*/}
              {/*    <li className={`${path === 'cards' ? 'mm-active' : ''}`}>*/}
              {/*      <Link*/}
              {/*        className="has-arrow ai-icon"*/}
              {/*        to="/cards"*/}
              {/*        onClick={() => this.props.onClick()}*/}
              {/*      >*/}
              {/*        {cards}*/}
              {/*        <span className="nav-text">Cards</span>*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*  )}*/}
              {/*{AccountType.B === user.accountType && (*/}
              {/*  <li*/}
              {/*    className={`${*/}
              {/*      path === 'employee-management' ? 'mm-active' : ''*/}
              {/*    }`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link className="has-arrow ai-icon" to="/employee-management">*/}
              {/*      {EmployeeMgmt}*/}
              {/*      <span className="nav-text">Employee Management</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}

              {/*{AccountType.P === user.accountType && enableReimbursement && (*/}
              {/*  <li*/}
              {/*    className={`${*/}
              {/*      path === 'request-reimbursement' ? 'mm-active' : ''*/}
              {/*    }`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link*/}
              {/*      className="has-arrow ai-icon"*/}
              {/*      to="/request-reimbursement"*/}
              {/*    >*/}
              {/*      {EmployeeMgmt}*/}
              {/*      <span className="nav-text">Reimbursement</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/*{AccountType.B === user.accountType && (*/}
              {/*  <li*/}
              {/*    className={`${path === 'integration' ? 'mm-active' : ''}`}*/}
              {/*    onClick={() => this.props.onClick()}*/}
              {/*  >*/}
              {/*    <Link className="has-arrow ai-icon" to="/integration">*/}
              {/*      {integration}*/}
              {/*      <span className="nav-text">Integration</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}

              {(user?.accountType === AccountType.P ||
                user?.accountType === AccountType.B) && (
                <li className={`${path === 'supports' ? 'mm-active' : ''}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    onClick={() => this.props.onClick()}
                  >
                    {supports}
                    <span className="nav-text">Supports</span>
                  </Link>
                </li>
              )}
              <li className="log-out">
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  onClick={this.LogoutHandler}
                >
                  {logout}
                  <span className="nav-text">Log Out</span>
                </Link>
              </li>
            </MM>
            {/* </PerfectScrollbar> */}
          </div>
        ) : (
          <div className="deznav bg-white">
            <PerfectScrollbar className="deznav-scroll">
              <MM className="disabled-menu" id="menu">
                <li>
                  <a className="has-arrow ai-icon">
                    {dashboard}
                    <span className="nav-text">Dashboard</span>
                  </a>
                </li>
                <li>
                  <a className="has-arrow ai-icon">
                    {transactions}
                    <span className="nav-text">Transactions</span>
                  </a>
                </li>
                <li>
                  <a className="has-arrow ai-icon">
                    {transfers}
                    <span className="nav-text">Transfers</span>
                  </a>
                </li>
                <li>
                  <a className="has-arrow ai-icon">
                    {cards}
                    <span className="nav-text">Cards</span>
                  </a>
                </li>
                <li>
                  <a className="has-arrow ai-icon">
                    {investment}
                    <span className="nav-text">Investment</span>
                  </a>
                </li>
                <li>
                  <a>
                    {supports}
                    <span className="nav-text">Supports</span>
                  </a>
                </li>
                <li className="log-out">
                  <Link
                    to="#"
                    className="has-arrow ai-icon"
                    onClick={this.LogoutHandler}
                  >
                    {logout}
                    <span className="nav-text">Log Out</span>
                  </Link>
                </li>
              </MM>
            </PerfectScrollbar>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withReimbursementInfo(SideBar));

function withReimbursementInfo(Component) {
  return function WrappedComponent(props) {
    const { data: userEmployee = [] } = useCheckIsEmployeeQuery();

    return <Component {...props} enableReimbursement={!!userEmployee.length} />;
  };
}

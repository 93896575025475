import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import axios from 'axios';
import { FaFilePdf } from 'react-icons/fa';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  useFetchCompanyLegalForm,
  useFetchCompanySize,
  useFetchCountries,
  useFetchRiskLevel,
  useRegisterBusiness,
  useValidateEmail,
} from '../../../../services/User';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Chip,
  CssBaseline,
  Container,
  useTheme,
  useMediaQuery,
  Stack,
  CardActions,
  FormHelperText,
} from '@mui/material';
import { InputLabel } from '../../../components/TextFieldFormik/styles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getInitials } from 'assets/utils';
import styled from 'styled-components';
import { FaPhoneAlt, FaLocationArrow } from 'react-icons/fa';
import { Form, FormikProvider, useFormik } from 'formik';
import SelectMenu from 'jsx/components/UI/Select';
import { set } from 'date-fns';
import ShowDocuments from './showdocument';

const UserDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { row } = location.state || {};
  const [documents, setDocuments] = useState({
    city: '',
    taxpayer_identification_number: '',
    number_of_directors: 0,
    documents: [],
  });
  const { data: riskLevel } = useFetchRiskLevel();
  const { data: companySize } = useFetchCompanySize();
  const { data: companyLegalForm } = useFetchCompanyLegalForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [view, setView] = useState('');
  const { state } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tabletS'));
  const hasIbanAndAmount = row?.ledgers?.some(
    (ledger) => ledger?.iban || ledger?.amount >= 0
  );
  const [loadingState, setLoadingState] = useState({});
  useEffect(() => {
    {
      row?.accountType === 'business' &&
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/documents?id=${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${state.auth.accessToken}`,
                'x-refresh': `${state.auth.refreshToken}`,
              },
            }
          )
          .then((res) => {
            setDocuments({
              company_details: res.data.company_details,
              number_of_directors: res.data.number_of_directors,
              documents: res.data.company_details?.documents,
            });

            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    {
      row?.accountType === 'personal' &&
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/personal/documents?id=${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${state.auth.accessToken}`,
                'x-refresh': `${state.auth.refreshToken}`,
              },
            }
          )
          .then((res) => {
            setDocuments({
              personal_details: res?.data?.personalDetails,
              documents: res?.data?.personalDetails?.documents,
            });

            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, []);

  const updateDocumentStatus = (documentPath, newStatus) => {
    setDocuments((prevDocuments) => ({
      ...prevDocuments,
      documents: prevDocuments.documents.map((doc) =>
        doc.documentPath === documentPath
          ? { ...doc, documentStatus: newStatus }
          : doc
      ),
    }));
  };

  const getInitialValues = (accountType) => {
    if (accountType === 'business') {
      return {
        name: documents?.company_details?.name || '',
        email: row?.email || '',
        planned_income: documents?.company_details?.planned_income || '',
        planned_expenditure:
          documents?.company_details?.planned_expenditure || '',
        company_size_id: documents?.company_details?.company_size_id || '',
        legal_form_id: documents?.company_details?.legal_form_id || '',
        risk_level: documents?.company_details?.risk_level || '',
      };
    } else if (accountType === 'personal') {
      return {
        place_of_birth: documents?.personal_details?.place_of_birth || '',
        email: documents?.personal_details?.email || '',
        national_id: documents?.personal_details?.national_id || '',
        risk_level: documents?.personal_details?.risk_level || '',
        address_postal_code:
          documents?.personal_details?.address?.address_postal_code || '',
      };
    }
    return {};
  };

  const initialValues = getInitialValues(row?.accountType);

  const fromIkBag = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleUpdateUser(values, row._id);
    },
  });

  useEffect(() => {
    const updatedValues = getInitialValues(row?.accountType);
    fromIkBag.setValues(updatedValues);
  }, [documents, row?.accountType]);

  useEffect(() => {
    if (selectOptions) {
      setSelectOptions({
        riskLevel: riskLevel?.result?.map((obj) => {
          return {
            label: obj.risk_level,
            value: obj.id,
          };
        }),
        companySize: companySize?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
        companyLegalForm: companyLegalForm?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
      });
    }
  }, [riskLevel, companySize, companyLegalForm]);

  const handleButtonClick = async (action, path) => {
    const data = {
      userId: row._id,
      documentId: path,
      documentStatus: action,
    };
    setLoadingState((prevState) => ({
      ...prevState,
      [path + action]: true,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/documents/status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
      updateDocumentStatus(path, action);
      setIsLoading(false);
    } catch (err) {
      console.log("error", err);
      
      swal({
        title: 'Document Status Approval Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setLoadingState((prevState) => ({
        ...prevState,
        [path + action]: false,
      }));
    }
  };
  const business = () => {
    const isLargeScreen = window.innerWidth > 1200;
    return 4;
  };

  const handleApproveUser = async (userId) => {
    setIsSubmitting(true);
    try {
      let data = {
        userId: userId,
        kycStatus: 'approved',
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/update/kyc-status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
      swal({
        title: 'User Approved Successfully',
        icon: 'success',
        text: `${res.data.message}`,
        button: true,
      });
    } catch (err) {
      swal({
        title: 'User Approval Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setIsSubmitting(false);
    }
  };

  const handleUpdateUser = async (formData, userId) => {
    setIsSubmitting(true);
    try {
      let data = {
        userId: userId,
      };
      let res;
      if (row?.accountType === 'business') {
        if (formData) {
          let companyDetails = {
            name: formData.name,
            email: formData.email,
            planned_income: formData.planned_income,
            planned_expenditure: formData.planned_expenditure,
            company_size_id: formData.company_size_id,
            legal_form_id: formData.legal_form_id,
            risk_level: formData.risk_level,
          };
          companyDetails = Object.fromEntries(
            Object.entries(companyDetails).filter(
              ([_, value]) => value !== undefined && value !== ''
            )
          );
          if (Object.keys(companyDetails).length > 0) {
            data.company_details = companyDetails;
          }
        }
        res = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/update/business`,
          data,
          {
            headers: {
              Authorization: `Bearer ${state.auth.accessToken}`,
              'x-refresh': `${state.auth.refreshToken}`,
            },
          }
        );
      } else if (row?.accountType === 'personal') {
        if (formData) {
          let personalDetails = {
            place_of_birth: formData.place_of_birth,
            email: formData.email,
            national_id: formData.national_id,
            risk_level: formData.risk_level,
            address_postal_code: formData.address_postal_code,
          };
          personalDetails = Object.fromEntries(
            Object.entries(personalDetails).filter(
              ([_, value]) => value !== undefined && value !== ''
            )
          );
          if (Object.keys(personalDetails).length > 0) {
            data.user_details = personalDetails;
          }
        }
        res = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/update/personal`,
          data,
          {
            headers: {
              Authorization: `Bearer ${state.auth.accessToken}`,
              'x-refresh': `${state.auth.refreshToken}`,
            },
          }
        );
      }

      swal({
        title: 'User updated Successfuly',
        icon: 'success',
        text: `${res.data.message}`,
        button: true,
      });

      history.push('/users');
    } catch (err) {
      swal({
        title: 'User updation Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setIsSubmitting(false);
    }
  };

  const areAllDocumentsApproved = () => {
    if (documents && documents?.documents) {
      return documents.documents.every(
        (doc) => doc.documentStatus === 'approved'
      );
    }
    return false;
  };

  const Alldataavailable = (value) => {
    if (row.accountType === 'personal' && !documents?.documents) {
      history.push(`/personal-form/${row._id}`);
    } else if (
      documents.number_of_directors == 0 &&
      row?.accountType !== 'personal'
    ) {
      history.push(`/directors/${row._id}`);
    } else if (documents.number_of_directors > 0 && !documents?.documents) {
      history.push(`/documents/${row._id}`);
    }
    setView(value);
  };

  return (
    <div className="d-flex flex-column text-capitalize">
      {view === '' && (
        <>
          {' '}
          <hr
            style={{
              border: '1px solid #d0d4e2',
              width: '100%',
              marginTop: '-1.9rem',
              marginBottom: '1rem',
            }}
          />
          <ResponsiveContainer>
            <div className="d-flex justify-content-between  w-100 align-items-center mb-4">
              <div className="d-flex gap-5 justify-content-center align-items-center mr-5">
                <div className="d-flex gap-5 justify-content-start align-items-start mr-5 flex-column">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Full Name:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {row?.full_name?.first_name} {row?.full_name?.last_name}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {row?.accountType === 'business' ? (
                      <>
                        <p className="m-0" style={{ minWidth: '130px' }}>
                          {' '}
                          Tax Id No:
                        </p>
                        <p
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '500',
                          }}
                        >
                          {
                            documents?.company_details
                              ?.taxpayer_identification_number
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="m-0" style={{ minWidth: '130px' }}>
                          Date of birth:
                        </p>
                        <p
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '500',
                          }}
                        >
                          {documents?.personal_details?.date_of_birth}
                        </p>
                      </>
                    )}
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      City:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {documents?.company_details?.city}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Phone number:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {row?.phone_number}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Status:
                    </p>{' '}
                    <div
                      className="d-flex justify-content-center align-items-center px-2 py-1"
                      style={{
                        backgroundColor:
                          row?.status === 'approved' ? 'green' : '#e1df10',
                        borderRadius: '10px',
                      }}
                    >
                      <p
                        style={{
                          color: 'var(--white)',
                          margin: '0px',
                          fontSize: '13px',
                        }}
                      >
                        {row?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ResponsiveContainer>
          <div
            className="d-flex flex-column flex-lg-row justify-content-between align-items-start"
            style={{ gap: '10px' }}
          >
            <div
              className="px-4 pt-3 w-100 "
              style={{
                minHeight: '200px',
                border: '1px solid #d0d4e2',
                borderRadius: '3px',
                overflow: 'hidden',
              }}
            >
              <h3>Account Information</h3>
              <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">Email</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {row?.email}
                  </p>
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  {row?.accountType === 'business' ? (
                    <>
                      <p className="m-0">Company Name:</p>
                      <p
                        style={{
                          margin: '0px',
                          color: 'black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                      >
                        {row?.company_details?.name}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="m-0">National ID:</p>
                      <p
                        style={{
                          margin: '0px',
                          color: 'black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                      >
                        {documents?.personal_details?.national_id}
                      </p>
                    </>
                  )}
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">Registered at:</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {new Date(row?.createdAt).toISOString().split('T')[0]}
                  </p>
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">KYC Status:</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {row?.kyc?.status}
                  </p>
                </div>
              </div>
            </div>

            {hasIbanAndAmount && (
              <div
                className="px-4 pt-3 w-100 "
                style={{
                  minHeight: '200px',
                  border: '1px solid #d0d4e2',
                  borderRadius: '3px',
                }}
              >
                <h3>Ledger Information</h3>
                <table
                  className="table w-100"
                  style={{ borderRadius: '10px', tableLayout: 'fixed' }}
                >
                  <thead
                    style={{
                      borderBottom: '1px solid gray',
                      backgroundColor: 'white',
                      color: '#7e7e7e',
                    }}
                  >
                    <tr>
                      <th className="fs-13 text-uppercase w-50">IBAN</th>
                      <th className="fs-13 text-uppercase w-50">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.ledgers.map((ledger, idx) => (
                      <tr
                        key={idx}
                        style={{
                          backgroundColor: idx % 2 !== 0 ? 'white' : '',
                        }}
                      >
                        <td
                          style={{
                            color: 'var(--gray-dark)',
                            fontWeight: '500',
                            lineHeight: '1.2',
                            fontSize: '14px',
                          }}
                        >
                          {ledger?.iban ? ledger.iban : 'N/A'}
                        </td>
                        <td
                          style={{
                            color: 'var(--gray-dark)',
                            fontSize: '14px',
                            lineHeight: '1.2',
                            fontWeight: '500',
                          }}
                        >
                          {ledger?.amount >= 0 ? ledger.amount : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <Button
              variant="contained"
              type="submit"
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => Alldataavailable('editProfile')}
            >
              Edit Profile
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => Alldataavailable('businessDocument')}
            >
              View Document
            </Button>
          </div>
        </>
      )}
      {view === 'businessDocument' &&
        <ShowDocuments documents={documents} setDocuments={setDocuments} row={row} Alldataavailable={Alldataavailable} />}
      {view === 'editProfile' && (
        <>
          {' '}
          <Card
            sx={{
              mx: 4,
              py: 5,
              boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
              borderRadius: 4,
            }}
          >
            <FormikProvider value={fromIkBag}>
              <Form onSubmit={fromIkBag.handleSubmit}>
                <CardContent
                  sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="center"
                    fontWeight="medium"
                    mx="auto"
                    mb={6}
                    color="var(--primary)"
                    sx={{ maxWidth: '60%' }}
                  >
                    Update Information
                  </Typography>

                  <Box
                    sx={{
                      maxWidth: isMobile ? '80%' : '100%',
                      margin: 'auto',
                    }}
                  >
                    <Grid container spacing={2}>
                      {row.accountType === 'business' && (
                        <>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Company Name"
                                type="text"
                                name="name"
                                placeholder="Eg:Apple"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Email"
                                type="text"
                                name="email"
                                placeholder="Email"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Legal Form</InputLabel>
                              <SelectMenu
                                options={selectOptions?.companyLegalForm}
                                value={selectOptions?.companyLegalForm?.find(
                                  (language) =>
                                    language.value ===
                                    fromIkBag.values.legal_form_id
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'legal_form_id',
                                    option?.value
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.legal_form_id &&
                                !!fromIkBag.touched.legal_form_id && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.legal_form_id}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Company Size</InputLabel>
                              <SelectMenu
                                options={selectOptions?.companySize}
                                value={selectOptions?.companySize?.find(
                                  (language) =>
                                    language.value ===
                                    fromIkBag.values.company_size_id
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'company_size_id',
                                    option?.value
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.company_size_id &&
                                !!fromIkBag.touched.company_size_id && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.company_size_id}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Risk level</InputLabel>
                              <SelectMenu
                                options={selectOptions?.riskLevel}
                                value={selectOptions?.riskLevel?.find(
                                  (language) =>
                                    language.label ===
                                    fromIkBag.values.risk_level
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'risk_level',
                                    option?.label
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.risk_level &&
                                !!fromIkBag.touched.risk_level && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.risk_level}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="PLANNED INCOME"
                                type="text"
                                name="planned_income"
                                placeholder="Planned Income"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="PLANNED EXPENDITURE"
                                type="text"
                                name="planned_expenditure"
                                placeholder="Planned Expenditure"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                      {row.accountType === 'personal' && (
                        <>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Place of Birth"
                                type="text"
                                name="place_of_birth"
                                placeholder="Place of Birth"
                                fullWidth
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Email"
                                type="text"
                                name="email"
                                placeholder="Email"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="National ID"
                                type="text"
                                name="national_id"
                                placeholder="National ID"
                                fullWidth
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Risk level</InputLabel>
                              <SelectMenu
                                options={selectOptions?.riskLevel}
                                value={selectOptions?.riskLevel?.find(
                                  (language) =>
                                    language.label ===
                                    fromIkBag.values.risk_level
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'risk_level',
                                    option?.label
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.risk_level &&
                                !!fromIkBag.touched.risk_level && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.risk_level}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Postal Code"
                                type="text"
                                name="address_postal_code"
                                placeholder="Eg:1050"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </CardContent>

                <CardActions
                  sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                    pr: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ maxWidth: 150 }}
                    onClick={() => Alldataavailable('')}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="contained"
                    component={LoadingButton}
                    loading={isSubmitting}
                    size="small"
                    sx={{ maxWidth: 150 }}
                    type="submit"
                  >
                    Save
                  </Button>
                </CardActions>
              </Form>
            </FormikProvider>
          </Card>
        </>
      )}
    </div>
  );
};

const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 0;

  @media (min-width: 768px) {
  }
`;

const ImagePlaceholder = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  text-shadow: -1px -1px 0 #8b9db8, 1px -1px 0 #8b9db8, -1px 1px 0 #8b9db8,
    1px 1px 0 #8b9db8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: 5px solid var(--primary);
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  font-weight: bold;
  color: var(--white);
  margin-right: 5px;

  @media (max-width: 550px) {
    width: 70px;
    font-size: 34px;
    height: 70px;
  }
`;

export default UserDetails;

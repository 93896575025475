import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserProfileImage from 'images/user-profile.jpeg';

import { ThemeContext } from '../../../context/ThemeContext';
import NotificationList from 'jsx/pages/notification/NotificationList';
import { io } from 'socket.io-client';
import {
  useFetchNotificationCount,
  useFetchNotificationList,
} from '../../../services/notification';
import { useUploadUserImage } from 'services/User';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderContext = createContext();
export const useHeaderContext = () => useContext(HeaderContext);
export const HeaderContextProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('');

  const updateHeaderTitle = (title) => {
    setHeaderTitle(title);
  };

  return (
    <HeaderContext.Provider value={{ headerTitle, updateHeaderTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  const { windowWidth, userData, user, state } = useContext(ThemeContext);
  const { headerTitle } = useHeaderContext();

  const [userImage, setUserImage] = useState(UserProfileImage);

  useEffect(() => {
    if (user?.profileImage) {
      setUserImage(
        `${process.env.REACT_APP_BACKEND_API}/${user?.profileImage}`
      );
    }
  }, [user]);
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const isPathId = (path) => {
    return /^[0-9a-fA-F]{24}$/.test(path);
  };
  const path = isPathId(pathSegments[pathSegments.length - 1])
    ? pathSegments[pathSegments.length - 2]
    : pathSegments[pathSegments.length - 1];

  const { row } = location.state || {};

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              {(path === 'dashboard' || path === 'profile' || path === '') && (
                <img
                  src={userImage}
                  onError={() => setUserImage(UserProfileImage)}
                  width={windowWidth < 700 ? 40 : 66}
                  height={windowWidth < 700 ? 40 : 66}
                  className="rounded-circle mr-3"
                  alt="user-image"
                  style={{ objectFit: 'contain' }}
                />
              )}
              {isPathId(pathSegments[pathSegments.length - 1]) ? (
                <Fragment>
                  <div className="header-info">
                    <h3 className="mb-0 text-capitalize">
                      {' '}
                      {row?.company_details?.name
                        ? row.company_details.name
                        : row?.full_name
                        ? Object.values(row.full_name).join(' ')
                        : headerTitle || path}
                    </h3>
                  </div>
                </Fragment>
              ) : path === 'dashboard' || path === '' ? (
                <Fragment>
                  <div className="header-info">
                    <p className="fs-14 text-black mb-0 text-uppercase font-weight-bolder">
                      <strong>
                        Hi{' '}
                        {row?.company_details?.name ||
                          user?.full_name?.first_name}
                        ,
                      </strong>
                    </p>
                    <h3 className="mb-0">Welcome to DMONEI!</h3>
                  </div>
                </Fragment>
              ) : (
                path !== 'profile-update' && (
                  <Fragment>
                    <div className="header-info">
                      <h3 className="mb-0 text-capitalize">
                        {headerTitle || path}
                      </h3>
                    </div>
                  </Fragment>
                )
              )}
            </div>

            <ul className="navbar-nav header-right align-items-center">
              {windowWidth < 540 ? (
                <Dropdown as="li" className="nav-item notification_dropdown ">
                  <Dropdown.Toggle
                    variant=""
                    className="nav-link bell bell-link i-false"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
                      </g>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="right"
                    className="mt-2"
                    style={{ minWidth: '55px' }}
                  >
                    <Menu state={state} />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Menu state={state} />
              )}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const Menu = ({ state }) => {
  const { initUserDetails } = useContext(ThemeContext);
  const { refetch } = useFetchNotificationList();
  const { data } = useFetchNotificationCount(state);
  const { mutateAsync: uploadImage } = useUploadUserImage();

  const [notificationCount, setNotificationCount] = useState(0);
  const imageFieldRef = useRef();
  const socket = useRef();

  const handlePhoto = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append('profileImage', file);
        await uploadImage(formData);
        initUserDetails();
      } catch (error) {
        console.log(error, 'Failed to update the image.');
      }
    }
    e.target.value = '';
  };

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BACKEND_API, {
      transports: ['websocket'],
      reconnectionAttempts: 2,
      upgrade: false,
      auth: {
        token: state.auth.accessToken,
      },
    });

    socket.current.on('notifications', (resp) => {
      setNotificationCount(resp);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    setNotificationCount(data?.count);
  }, [data]);

  return (
    <>
      <div>
        <label for="file-input" ref={imageFieldRef} />
        <input
          id="file-input"
          type="file"
          accept=".png, .jpg, .jpeg"
          name="profileImage"
          onChange={handlePhoto}
          hidden
        />
      </div>

      <Dropdown as="li" className="nav-item header-profile ">
        <Dropdown.Toggle
          id="dropdown-profile"
          variant=""
          className="nav-link i-false c-pointer"
        >
          <ProfileIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" className="mt-2">
          <Dropdown.Item as="div">
            <Link
              to="#"
              className="d-flex align-items-center dropdown-item ai-icon p-0"
              onClick={(e) => {
                e.preventDefault();
                imageFieldRef.current?.click();
              }}
            >
              <ProfileImage />
              <span className="ml-2">Upload Profile Picture</span>
            </Link>
          </Dropdown.Item>

          <Dropdown.Item as="div">
            <Link
              to="/profile"
              className="d-flex align-items-center dropdown-item ai-icon p-0"
            >
              <PersonalInfo />
              <span className="ml-2">Profile Information </span>
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown as="li" className="nav-item header-profile ">
        <Dropdown.Toggle
          id="dropdown-beneficiary"
          variant=""
          className="nav-link i-false c-pointer"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7171 0.000244141C11.4734 0.000244141 12.1581 0.420244 12.5362 1.04024C12.7201 1.34024 12.8428 1.71024 12.8121 2.10024C12.7917 2.40024 12.8837 2.70024 13.0472 2.98024C13.5684 3.83024 14.7232 4.15024 15.6225 3.67024C16.6342 3.09024 17.9117 3.44024 18.4942 4.43024L19.1789 5.61024C19.7716 6.60024 19.4446 7.87024 18.4227 8.44024C17.554 8.95024 17.2474 10.0802 17.7686 10.9402C17.9321 11.2102 18.1161 11.4402 18.4022 11.5802C18.7599 11.7702 19.0358 12.0702 19.23 12.3702C19.6081 12.9902 19.5775 13.7502 19.2096 14.4202L18.4942 15.6202C18.1161 16.2602 17.4109 16.6602 16.6853 16.6602C16.3277 16.6602 15.9291 16.5602 15.6021 16.3602C15.3364 16.1902 15.0298 16.1302 14.7027 16.1302C13.691 16.1302 12.8428 16.9602 12.8121 17.9502C12.8121 19.1002 11.8719 20.0002 10.6967 20.0002H9.3068C8.12133 20.0002 7.18113 19.1002 7.18113 17.9502C7.16069 16.9602 6.31247 16.1302 5.30073 16.1302C4.96348 16.1302 4.6569 16.1902 4.40141 16.3602C4.07438 16.5602 3.6656 16.6602 3.31813 16.6602C2.58232 16.6602 1.87717 16.2602 1.49905 15.6202L0.793898 14.4202C0.415774 13.7702 0.395334 12.9902 0.773459 12.3702C0.936972 12.0702 1.24356 11.7702 1.59102 11.5802C1.87717 11.4402 2.06113 11.2102 2.23486 10.9402C2.74584 10.0802 2.43925 8.95024 1.57059 8.44024C0.558848 7.87024 0.231821 6.60024 0.814337 5.61024L1.49905 4.43024C2.09178 3.44024 3.35901 3.09024 4.38097 3.67024C5.27007 4.15024 6.42488 3.83024 6.94608 2.98024C7.1096 2.70024 7.20157 2.40024 7.18113 2.10024C7.16069 1.71024 7.27311 1.34024 7.46728 1.04024C7.8454 0.420244 8.53012 0.0202441 9.27614 0.000244141H10.7171ZM10.012 7.18024C8.40748 7.18024 7.1096 8.44024 7.1096 10.0102C7.1096 11.5802 8.40748 12.8302 10.012 12.8302C11.6164 12.8302 12.8837 11.5802 12.8837 10.0102C12.8837 8.44024 11.6164 7.18024 10.012 7.18024Z"
              fill="#A0AEC0"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" className="mt-2">
          <Dropdown.Item as="div">
            <Link
              to="/email-config"
              className="d-flex align-items-center dropdown-item ai-icon p-0"
            >
              <EmailAlert />
              <span className="ml-2">Manage Email Notifications</span>
            </Link>
          </Dropdown.Item>

          <Dropdown.Item as="div">
            <Link
              to="/beneficiary"
              className="d-flex align-items-center dropdown-item ai-icon p-0"
            >
              <svg
                id="icon-user1"
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
              <span className="ml-2">Manage Beneficiaries </span>
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown as="li" className="nav-item  notification_dropdown">
        <Dropdown.Toggle
          id="dropdown-notification"
          variant=""
          className="nav-link  ai-icon i-false"
        >
          <svg
            onClick={refetch}
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.96318 17.2279C7.46309 17.1222 10.5093 17.1222 11.0092 17.2279C11.4366 17.3266 11.8987 17.5573 11.8987 18.0608C11.8739 18.5402 11.5926 18.9653 11.204 19.2352C10.7001 19.628 10.1088 19.8767 9.49057 19.9664C9.14868 20.0107 8.81276 20.0117 8.4828 19.9664C7.86362 19.8767 7.27227 19.628 6.76938 19.2342C6.37978 18.9653 6.09852 18.5402 6.07367 18.0608C6.07367 17.5573 6.53582 17.3266 6.96318 17.2279ZM9.04522 0C11.1254 0 13.2502 0.987019 14.5125 2.62466C15.3314 3.67916 15.7071 4.73266 15.7071 6.3703V6.79633C15.7071 8.05226 16.039 8.79253 16.7695 9.64559C17.3231 10.2741 17.5 11.0808 17.5 11.956C17.5 12.8302 17.2128 13.6601 16.6373 14.3339C15.884 15.1417 14.8215 15.6573 13.7372 15.747C12.1659 15.8809 10.5937 15.9937 9.0005 15.9937C7.40634 15.9937 5.83505 15.9263 4.26375 15.747C3.17846 15.6573 2.11602 15.1417 1.36367 14.3339C0.78822 13.6601 0.5 12.8302 0.5 11.956C0.5 11.0808 0.677901 10.2741 1.23049 9.64559C1.98384 8.79253 2.29392 8.05226 2.29392 6.79633V6.3703C2.29392 4.68834 2.71333 3.58852 3.577 2.51186C4.86106 0.941697 6.91935 0 8.95577 0H9.04522Z"
              fill="#A0AEC0"
            />
          </svg>
          {notificationCount !== 0 && (
            <span className="badge badge-danger">
              {notificationCount > 99 ? '99+' : notificationCount}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" className="mt-2">
          <NotificationList />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Header;

const EmailAlert = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-bell"
      viewBox="0 0 16 16"
    >
      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"></path>
    </svg>
  );
};

const ProfileIcon = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13.1739C12.3386 13.1739 16 13.8789 16 16.5989C16 19.3199 12.3146 19.9999 8 19.9999C3.66237 19.9999 0 19.2949 0 16.5749C0 13.8539 3.68538 13.1739 8 13.1739ZM8 -6.10352e-05C10.9391 -6.10352e-05 13.294 2.35396 13.294 5.29099C13.294 8.22802 10.9391 10.583 8 10.583C5.0619 10.583 2.70601 8.22802 2.70601 5.29099C2.70601 2.35396 5.0619 -6.10352e-05 8 -6.10352e-05Z"
      fill="#A0AEC0"
    />
  </svg>
);

const ProfileImage = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="256" cy="272" r="64"></circle>
    <path d="M432 144h-59c-3 0-6.72-1.94-9.62-5l-25.94-40.94a15.52 15.52 0 00-1.37-1.85C327.11 85.76 315 80 302 80h-92c-13 0-25.11 5.76-34.07 16.21a15.52 15.52 0 00-1.37 1.85l-25.94 41c-2.22 2.42-5.34 5-8.62 5v-8a16 16 0 00-16-16h-24a16 16 0 00-16 16v8h-4a48.05 48.05 0 00-48 48V384a48.05 48.05 0 0048 48h352a48.05 48.05 0 0048-48V192a48.05 48.05 0 00-48-48zM256 368a96 96 0 1196-96 96.11 96.11 0 01-96 96z"></path>
  </svg>
);

const PersonalInfo = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      stroke-miterlimit="10"
      stroke-width="32"
      d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"
    ></path>
    <path
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M220 220h32v116"
    ></path>
    <path
      fill="none"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="32"
      d="M208 340h88"
    ></path>
    <path d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z"></path>
  </svg>
);
